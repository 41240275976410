import React from "react";
import { Box, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { DataGrid, GridColDef, GridCellParams } from '@material-ui/data-grid';

import { GetAllYears, GetGrouppedPerformanceContribution } from "../../api/performance";
import { getCurrentProfile } from "../../api/profiles";
import { Profile, Value, ValueSeries, ViewParams } from "../../types";
import BarChartCard from "../common/BarChartCard";
import AsyncView from "../common/AsyncView";
import { useAsync } from "react-use";
import { GetBalance, GetBalanceHistory, getPensionProjection } from "../../api/balance";
import LineChartCard from "../common/LineChartCard";
import { GetSavingsHistory } from "../../api/savings";
import { GetIncomeHistory } from "../../api/income";
import { getAccountBalanceSum, getAccountPensionBalanceSum, getAccountPropertyBalanceSum, getAverageStats, getYearlyRecords, GetYearlyStats } from "../../api/stats";
import { Amount, AmountK, Percentage } from "../common/fragments";
import InfoCard from "../common/InfoCard";
import PieChartCard from "../common/PieChartCard";
import { getAccountSeriesSum, getValueSeriesSum } from "../../api/common";
import FamilyLineChart from "../common/FamilyLineChart";
import Y2DProgress from "./Y2DProgress";
import TrendSymbol from "../common/TrendSymbol";
import PercentageDistribution from "../common/PercentageDistribution";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
    }),
);

function EstimatedProgress({ balanceHistory, balance, profile, privacy }: { balanceHistory: ValueSeries, balance: number, profile: Profile, privacy?: boolean }) {
    const realBalance = balance || 0;
    const timeToPension = getPensionProjection(profile, realBalance);
    privacy = privacy || false;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1
    const years = Array.from(GetAllYears(balanceHistory));
    years.sort((a, b) => a - b)


    var yearlyBalance: Array<Value> = years.map(
        year => {
            const prevYearBalanceRecords = getYearlyRecords(balanceHistory, year - 1)
            const prevYearBalance = getAccountBalanceSum(prevYearBalanceRecords, profile.name) - getAccountPensionBalanceSum(prevYearBalanceRecords, profile.name) - getAccountPropertyBalanceSum(prevYearBalanceRecords, profile.name)
            const yearBalanceRecords = getYearlyRecords(balanceHistory, year)
            const yearBalance = getAccountBalanceSum(yearBalanceRecords, profile.name)
            const yearPensionBalance = getAccountPensionBalanceSum(yearBalanceRecords, profile.name)
            const yearPropertyBalance = getAccountPropertyBalanceSum(yearBalanceRecords, profile.name)
            const estimatedEOYBalance = yearBalance - yearPensionBalance - yearPropertyBalance + 150000 * (12 - currentMonth) / 12
            return {
                x: year,
                name: year.toString(),
                estimatedValue: year === currentYear ? estimatedEOYBalance : undefined,
                value: yearBalance - yearPensionBalance - yearPropertyBalance,
            }
        }
    )
    var balanceSeries = yearlyBalance.concat(timeToPension.estimatedYearBalance)

    return <InfoCard title="Estimated yearly progress (excluding Pension, Property)">
        <FamilyLineChart data={balanceSeries} profile={profile} privacy={privacy} estimateData={true} />
        <p>Time to retirement: {timeToPension.years} years</p>
    </InfoCard>
}

function PerformanceContribution({ savings, balance, profile, privacy }: { savings: ValueSeries, balance: ValueSeries, profile: Profile, privacy: boolean }) {
    const data = GetGrouppedPerformanceContribution(savings, balance, profile.name)
    const totalContribution = getAccountSeriesSum(data.contribution);
    const totalLoss = getAccountSeriesSum(data.loss);

    return <>
        <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
                <EstimatedProgress balanceHistory={balance} balance={data.balance.balanceNoPP} profile={profile} privacy={privacy} />
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
                <BarChartCard data={data.performance} title="Asset Performance" profile={profile} />
            </Grid>
            <Grid item xs={12} lg={3}>
                <PieChartCard data={data.contribution} title="Asset Contribution" privacy={privacy}>
                    <p>Total investment return: <Amount value={totalContribution} privacy={privacy} /></p>
                    <p>Total investment loss: <Amount value={totalLoss} privacy={privacy} /></p>
                </PieChartCard>
            </Grid>
            <Grid item xs={12} lg={4}>
                <LineChartCard data={data.depositsAndAccounts} title="Deposit history" profile={profile} privacy={privacy} balanceValues={true} />
            </Grid>
        </Grid>
    </>
}

function YearlyPerformance({ savings, balance, income, profile, privacy }: { savings: ValueSeries, balance: ValueSeries, income: ValueSeries, profile: Profile, privacy: boolean }) {
    const years = GetAllYears(balance);
    const stats = GetYearlyStats(years, savings, balance, income, profile)
    const renderAmount = (params: GridCellParams) => <>{params.value !== "-" && <Amount value={parseInt(params.value?.toString() || '')} privacy={privacy} />}</>
    const renderPercentage = (params: GridCellParams) => <>{params.value !== "-" && <Percentage value={parseInt(params.value?.toString() || '')} />}</>
    const renderBalance = (params: GridCellParams) => <>{params.value !== "-" &&
        <Box display='flex' alignItems='flex-start' flexDirection='column' flexGrow='1' width='100%'>
            <Box display='flex' style={{ marginLeft: 'auto' }}>{renderAmount(params)}&nbsp;
                <small>[I: <AmountK value={params.row.balanceNoPension - params.row.cash - params.row.property} />&nbsp;
                    P2: <AmountK value={params.row.balanceP2} privacy={privacy} />&nbsp;
                    P3: <AmountK value={params.row.balanceP3} privacy={privacy} />,
                    C: <AmountK value={params.row.cash} privacy={privacy} />,
                    PP: <AmountK value={params.row.property} privacy={privacy} />]</small>
            </Box>
            <Box display='flex' width='100%'>
                <PercentageDistribution values={[
                    { value: params.row.balanceNoPension - params.row.cash, class: 'Investment', name: 'investment', x: 1 },
                    { value: params.row.balanceP2, class: 'Pension', name: 'P2', x: 2 },
                    { value: params.row.balanceP3, class: 'Pension', name: 'P3', x: 3 },
                    { value: params.row.cash, class: 'Cash', name: 'cash', x: 4 },
                    { value: params.row.property, class: 'Property', name: 'PP', x: 5 },
                ]} />
            </Box>
        </Box>}
    </>;
    const renderTrend = (params: GridCellParams) => {
        const trend = params.value ? parseInt(params.value.toString()) - params.row.prevYear[params.field] : 0;
        return <TrendSymbol trend={trend} />
    }
    const renderAmountTrend = (params: GridCellParams) => {
        return <>{renderTrend(params)}{renderAmount(params)}</>;
    }
    const renderPercentageTrend = (params: GridCellParams) => {
        return <>{renderTrend(params)}{renderPercentage(params)}</>;
    }
    const allStats = stats.concat([getAverageStats(stats)])

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Year', width: 90 },
        { field: 'savingsRate', headerName: 'SR', align: 'right', width: 80, renderCell: renderPercentageTrend },
        { field: 'balanceIncreaseRate', headerName: 'IR', align: 'right', width: 70, renderCell: renderPercentageTrend },
        { field: 'income', headerName: 'Income', align: 'right', width: 130, renderCell: renderAmountTrend },
        { field: 'savings', headerName: 'Savings', align: 'right', width: 130, renderCell: renderAmountTrend },
        { field: 'spending', headerName: 'Spending', align: 'right', width: 130, renderCell: renderAmountTrend },
        // {field: 'balanceIncrease', headerName: 'Balance Increase', align: 'right', width: 130, renderCell: renderAmount },
        { field: 'yoyIncrease', headerName: 'YOY', align: 'right', width: 90, renderCell: renderPercentage },
        { field: 'balanceDelta', headerName: 'IR (w/o P2)', align: 'right', width: 130, renderCell: renderAmountTrend },
        { field: 'balance', headerName: 'TOTAL', align: 'right', width: 500, renderCell: renderBalance },
    ];
    return <InfoCard title="Yearly statistics">
        <div style={{ height: 500 }}>
            <DataGrid rows={allStats} columns={columns} />
        </div>
    </InfoCard>;
}

export default function ProgressPage(params: ViewParams) {
    const classes = useStyles();
    const currentProfile = getCurrentProfile(params.profile);

    const getSavings = () => GetSavingsHistory(params)
    const getBalance = () => GetBalanceHistory(params)
    const getIncome = () => GetIncomeHistory(params)
    const incomeProps = useAsync(getIncome, []);
    const savingsProps = useAsync(getSavings, []);
    const balanceProps = useAsync(getBalance, []);

    const allProps = useAsync(async () => await Promise.all([getSavings, getBalance, getIncome]));

    const { value, loading, error } = useAsync(() => GetBalance(params), []);

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                    <AsyncView {...allProps}>
                        {savingsProps.value && balanceProps.value && incomeProps.value && <Y2DProgress savings={savingsProps.value} balance={balanceProps.value} income={incomeProps.value} profile={currentProfile} privacy={params.privacy} />}
                    </AsyncView>
                </Grid>
            </Grid>
            {currentProfile.name != "Shared" && <AsyncView {...allProps}>
                {savingsProps.value && balanceProps.value && <PerformanceContribution savings={savingsProps.value} balance={balanceProps.value} profile={currentProfile} privacy={params.privacy} />}
            </AsyncView>}
            <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                    <AsyncView {...allProps}>
                        {savingsProps.value && balanceProps.value && incomeProps.value && <YearlyPerformance savings={savingsProps.value} balance={balanceProps.value} income={incomeProps.value} profile={currentProfile} privacy={params.privacy} />}
                    </AsyncView>
                </Grid>
            </Grid>
        </div >
    )
}

