export function Amount({ value, privacy }: { value: number, privacy?: boolean }) {
    return <>{privacy ? "xxx" : value && value.toLocaleString()} Fr.-</>;
}

function kFormatter(num: number, round: boolean = false) {
    if (round) {
        return Math.abs(num) > 999 ? Math.sign(num)*(Math.round(Math.abs(num)/1000)) + 'k' : Math.sign(num)*Math.abs(num)
    }
    return Math.abs(num) > 999 ? Math.sign(num)*(Math.round(Math.abs(num)/100)/10) + 'k' : Math.sign(num)*Math.abs(num)
}

export function AmountK({ value, privacy }: { value: number, privacy?: boolean }) {
    return <>{privacy ? "xxx" : value && kFormatter(value, true)} Fr.-</>;
}

export function Percentage({ value }: { value: number }) {
    return <>{value && Math.round(value) + "%"}</>;
}
