import { LinearProgress } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import React from "react"

export default function AsyncView({ value, loading, error, children }: { value?: any, loading: boolean, error?: Error, children?: React.ReactNode }) {
    if (loading) {
        return <LinearProgress variant="indeterminate" />
    } else if (error || !value) {
        return <Alert severity="error">{error && error.toString() || "Unspecified error"}</Alert>;
    } else {
        return <>{children}</>
    }
}