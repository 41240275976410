import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import React from "react";

export default function PrivacyToggle({ privacy, privacyCallback }: { privacy: boolean, privacyCallback: any }) {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        privacyCallback(event.target.checked)
    };

    return <FormGroup row>
        <FormControlLabel
            control={<Switch checked={privacy} onChange={handleChange} />}
            label="Privacy mode"
        />
    </FormGroup>;
}