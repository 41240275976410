import React from "react";
import { getClassColor, getValueSeriesSum } from "../../api/common";
import {Value} from "../../types";

export default function PercentageDistribution(props: { values: Array<Value> }) {
    const total = getValueSeriesSum(props.values);
    if (total === 0) {
        return <>nothing to graph</>;
    }
    return <div style={{borderRadius: '10%', height: 10, display: 'block', width: '100%'}}>
        {props.values.map(v =>
            <div style={{backgroundColor: getClassColor(v.class || ''), display: 'block', float: 'left', width: `${(v.value || 0)/total*100}%`, height: '100%'}} />)}
    </div>;
}