import React from "react";
import { Avatar, Card, CardContent, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import WorkIcon from '@material-ui/icons/Work';
import { cardStyles } from "./styles";
import { Value, ValueSeries } from "../../types";
import { ResponsiveContainer } from "recharts";
import { getCurrentProfile } from "../../api/profiles";
import { Amount } from "./fragments";

function ListItems({ data, privacy }: { data: ValueSeries, privacy: boolean }) {
    return <ResponsiveContainer width="100%" height={248}>
        <List style={{ maxHeight: '100%', overflow: 'auto' }}>
            {data.map((item: Value) => {
                const savingsProfile = getCurrentProfile(item.profile || "global")
                return <ListItem>
                    <ListItemAvatar>
                        <Avatar style={{ backgroundColor: savingsProfile.color }}>
                            {savingsProfile.initials}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.destination} secondary={(new Date(item.x * 1000)).toLocaleDateString()} />
                    <ListItemSecondaryAction>
                       <Amount value={item.value || 0} privacy={privacy} />
                    </ListItemSecondaryAction>
                </ListItem>;
            }
            )}
        </List>
    </ResponsiveContainer>;
}

export default function ListCard({ data, title, privacy }: { data: any, title?: string, privacy: boolean }) {
    const classes = cardStyles();

    return <Card>
        <CardContent>
            {title && <Typography className={classes.title} color="textSecondary" gutterBottom align="left">
                {title}
            </Typography>}
            <ListItems data={data} privacy={privacy} />
        </CardContent>
    </Card>;
}