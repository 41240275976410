import React from "react";
import { LinearProgressProps, makeStyles, Theme, createStyles, LinearProgress } from "@material-ui/core";

interface BorderLinearProgressProps extends LinearProgressProps {
    barRadius?: number;
    barColor?: string;
};

export default function BorderLinearProgress(props: BorderLinearProgressProps) {
    const styles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                height: props.barRadius || 10,
                borderRadius: 5,
            },
            colorPrimary: {
                backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
            },
            bar: {
                borderRadius: 5,
                backgroundColor: props.barColor || "primary",
            },
        })
    );
    const classes = styles();
    return <LinearProgress classes={classes} {...props} />;
}