import React from "react"
import { Card, CardContent, Typography } from "@material-ui/core"
import { AccountSeries } from "../../types";
import FamilyPieChart from "./FamilyPieChart";
import { cardStyles } from "./styles";
import { getGrouppedAccounts } from "../../api/common";

export default function PieChartCard({ data, title, privacy, children }: { data: AccountSeries, title?: string, privacy: boolean, children?: React.ReactNode }) {
    const classes = cardStyles();

    if (!data) {
        return <></>;
    }

    data.sort((a, b) => {
        if (a.class == b.class) {
            return -(a.value - b.value);
        } else {
            return (a.class || "").localeCompare(b.class || "");
        }
    })
    const classData = getGrouppedAccounts(data, "sum");

    return <Card>
        <CardContent>
            {title && <Typography className={classes.title} color="textSecondary" gutterBottom align="left">
                {title}
            </Typography>}
            <FamilyPieChart dataPrimary={data} dataSecondary={classData} privacy={privacy} />
            {children}
        </CardContent>
    </Card>;
}