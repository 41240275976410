import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core"
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { ValueSeries, Profile } from "../../types";
import { Amount } from "../common/fragments";
import { getClassColor, getValueSeriesInterval, getValueSeriesSum } from "../../api/common";
import { cardStyles } from "../common/styles";
import { useAsync } from "react-use";
import { GetBalanceHistory, GetBalance } from "../../api/balance";
import { GetIncomeHistory } from "../../api/income";
import { GetSavingsHistory } from "../../api/savings";
import { getYearlyRecords } from "../../api/stats";
import { getAccountDeposits, getAccountNamesByClass, getAccountSavings, getAccountWithdrawals, getAggregatedAccountBalance } from "../../api/performance";
import TrendSymbol from "../common/TrendSymbol";

function getAYearStart(year: number): number {
    return new Date(year, 0, 1).getTime() / 1000;
}

function GetToolBreakdown(profile: Profile, tool: string, year: number, balanceHistory: ValueSeries, savingsHistory: ValueSeries) {
    balanceHistory.sort((a, b) => -(a.x - b.x));
    const yearBalanceRecords = getYearlyRecords(balanceHistory, year)
    const prevYearBalanceRecords = getYearlyRecords(balanceHistory, year - 1)

    const accountNames = getAccountNamesByClass(balanceHistory, tool);
    var accountBalance = -1;
    var prevAccountBalance = 0;
    if (!accountNames.length) {
        accountBalance = getAggregatedAccountBalance(tool, yearBalanceRecords, profile.name)
        prevAccountBalance = getAggregatedAccountBalance(tool, prevYearBalanceRecords, profile.name)
    } else {
        accountBalance = 0;
        accountNames.map((account) => {
            accountBalance += getAggregatedAccountBalance(account, yearBalanceRecords, profile.name)
            prevAccountBalance += getAggregatedAccountBalance(account, prevYearBalanceRecords, profile.name)
        })
    }
    if (accountBalance == -1) {
        // no balance could be found, assume initial
        accountBalance = prevAccountBalance;
    }

    const yearStart = getAYearStart(year)
    const yearEnd = getAYearStart(year + 1)
    const yearSavings = getValueSeriesInterval(getAccountSavings(tool, savingsHistory), yearStart, yearEnd)

    return {
        name: tool,
        initial: prevAccountBalance,
        final: accountBalance,
        deposits: getValueSeriesSum(getAccountDeposits(yearSavings)),
        withdrawn: getValueSeriesSum(getAccountWithdrawals(yearSavings)),
    }
}

function GetAllStats(tools: Array<string>, profile: Profile, year: number, balanceHistory: ValueSeries, savingsHistory: ValueSeries) {
    return tools.map((tool) => GetToolBreakdown(profile, tool, year, balanceHistory, savingsHistory))
}

function Summary({ year, incomeHistory, savingsHistory, privacy, currentYear }: { year: number, incomeHistory: ValueSeries, savingsHistory: ValueSeries, privacy: boolean, currentYear: boolean }) {
    const yearStart = getAYearStart(year)
    const yearEnd = getAYearStart(year + 1)
    const yearSavings = getValueSeriesSum(getValueSeriesInterval(savingsHistory, yearStart, yearEnd)) - getValueSeriesSum(getAccountSavings("Pillar 2", getValueSeriesInterval(savingsHistory, yearStart, yearEnd)))
    const yearIncome = getValueSeriesSum(getValueSeriesInterval(incomeHistory, yearStart, yearEnd))
    const savingsRate = Math.round(yearSavings / yearIncome * 100)

    const atSavings = getValueSeriesSum(savingsHistory) - getValueSeriesSum(getAccountSavings("Pillar 2", savingsHistory))
    const atP2 = getValueSeriesSum(getAccountSavings("Pillar 2", savingsHistory))
    const atP3 = getValueSeriesSum(getAccountSavings("Pension 3a", savingsHistory))
    const atIncome = getValueSeriesSum(incomeHistory)
    const atSavingsRate = Math.round(atSavings / atIncome * 100)

    return <>
        <p><strong>In {year}</strong> Income: <Amount value={yearIncome} privacy={privacy} />, Savings: <Amount value={yearSavings} privacy={privacy} /> ({savingsRate}%)</p>
        {currentYear && <p>
            <strong>All time</strong>
            <p>Income: <Amount value={atIncome} privacy={privacy} />,
                Savings: <Amount value={atSavings} privacy={privacy} /> ({atSavingsRate}%)<br />
                <span title="Included in Savings">P3</span>: <Amount value={atP3} privacy={privacy} />,
                P2: <Amount value={atP2} privacy={privacy} />
            </p>
        </p>}
    </>;
}

export default function AccountBreakdownPerYear({ year, profile, privacy, currentYear }: { year: number, profile: Profile, privacy: boolean, currentYear: boolean }) {
    const classes = cardStyles();

    const tools = ["TrueWealth", "GOOG", "GYG", "Degiro", "IBKR", "Lent", "Pension 3a", "Pillar 2", "Cash"];
    if (profile.name == "Shared") {
        tools.push("Property");
    }
    const params = {
        profile: profile.name,
        date: Date(),
        privacy: privacy,
    }

    const getSavings = () => GetSavingsHistory(params)
    const getBalance = () => GetBalanceHistory(params)
    const getIncome = () => GetIncomeHistory(params)
    const incomeProps = useAsync(getIncome, []);
    const savingsProps = useAsync(getSavings, []);
    const balanceProps = useAsync(getBalance, []);

    const allProps = useAsync(async () => await Promise.all([getSavings, getBalance, getIncome]));

    const { value, loading, error } = useAsync(() => GetBalance(params), []);


    return <Card>
        <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom align="left">
                Account breakdown for &nbsp;
                <Chip size="small" label={profile.name} style={{ color: 'white', backgroundColor: profile.color }} />
            </Typography>
            {incomeProps && savingsProps && <Summary year={year} incomeHistory={incomeProps.value || []} savingsHistory={savingsProps.value || []} privacy={privacy} currentYear={currentYear} />}
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Tool</TableCell>
                            <TableCell align="right">Initial</TableCell>
                            <TableCell align="right">Deposits</TableCell>
                            <TableCell align="right">Withdrawals</TableCell>
                            <TableCell align="right">Balance</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allProps && GetAllStats(tools, profile, year, balanceProps.value || [], savingsProps.value || []).map((tool) => {
                            if (tool.initial + tool.final == 0)
                                return <></>;
                            return <TableRow>
                                <TableCell>
                                    <Chip size="small" label={tool.name} style={{ color: 'white', backgroundColor: getClassColor(tool.name, "Investment") }} />
                                </TableCell>
                                <TableCell align="right"><Amount value={tool.initial} privacy={privacy} /></TableCell>
                                <TableCell align="right"><Amount value={tool.deposits} privacy={privacy} /></TableCell>
                                <TableCell align="right"><Amount value={tool.withdrawn} privacy={privacy} /></TableCell>
                                <TableCell align="right">
                                    <TrendSymbol trend={tool.final - (tool.initial + tool.deposits) - tool.withdrawn} />
                                    <Amount value={tool.final} privacy={privacy} />
                                </TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </CardContent>
    </Card>;
}
