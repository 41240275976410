import { Profile } from "../types";

export function getProfiles(): Array<Profile> {
    return [
        {
            name: "Alex",
            initials: "A",
            color: "#258EA6",
            target: 1000000,
            birthYear: 1987,
        },
        {
            name: "Claudia",
            initials: "C",
            color: "#ED6A5A",
            target: 1000000,
            birthYear: 1989,
        },
        {
            name: "Shared",
            initials: "S",
            color: "#555",
            target: 0,
            birthYear: 1988,
        },
        {
            name: "global",
            initials: "EF",
            color: "#9D79BC",
            target: 2000000,
            birthYear: 1988,
        }
    ];
}

export function getCurrentProfile(profile: string) {
    const profiles = getProfiles();
    return profiles.filter(item => item.name === profile)[0] || profiles[0];
}
