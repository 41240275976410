import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BottomNavigation, BottomNavigationAction, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom'
import HomeIcon from '@material-ui/icons/Home';
import Icon from '@material-ui/core/Icon';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { classes } from 'istanbul-lib-coverage';

const useStyles = makeStyles({
    root: {
        padding: 10,
        height: '85vh'
    },
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
    },
    piggy: {
        fontSize: 18,
        overflow: "visible",
    },
});


export default function MobileMenu({ children }: { children: ReactNode }) {
    const classes = useStyles();
    const pathname = window.location.pathname;
    const [value, setValue] = React.useState(pathname);
    const handleChange = (_event: any, newValue: string) => {
        setValue(newValue);
    };

    return (
        <div>
            <div className={classes.root}>
                {children}
            </div>
            <BottomNavigation
                value={value}
                onChange={handleChange}
                showLabels
                className={classes.stickToBottom}
            >
                <BottomNavigationAction label="Home" icon={<HomeIcon />} value="/" component={Link} to="/" />
                <BottomNavigationAction label="Savings" icon={<Icon className={"fa fa-piggy-bank " + classes.piggy} />} value="/savings" component={Link} to="/savings" />
                <BottomNavigationAction label="Progress" icon={<TrendingUpIcon />} value="/progress" component={Link} to="/progress" />
            </BottomNavigation>
        </div>
    );
}