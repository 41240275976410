import { useEffect, useState } from "react";
import { API_ENDPOINT, APP_ENDPOINT } from "../api/auth";

export default function CheckAuthentication() {
    const [error, setError] = useState<string>();

    useEffect(() => {
        async function doRequest() {
            const response = await fetch(API_ENDPOINT + "/auth/", { credentials: 'include' }).then(response => {
                if (response.ok) {
                    // Store response body normally
                    return;
                }
                if (response.status === 401) {
                    // needs authentication
                    const authenticateURL = API_ENDPOINT + "/backend/login" + "?redirect=" + APP_ENDPOINT;
                    setError("Need to authenticate: " + authenticateURL);
                    window.location.replace(authenticateURL);
                } else {
                    setError("Unexpected error: " + response.body);
                }
            }).catch((response) => {
                setError("error checking authentication: " + response.toString());
            });
        }
        doRequest();
    }, []);

    return <>{error && <h1>Error: {error}</h1>}</>;
}