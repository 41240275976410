import React from "react";
import { PolarAngleAxis, RadialBar, RadialBarChart, ResponsiveContainer } from "recharts";
import { Percentage } from "./fragments";

export default function ProgressCircle(props: { value: number, color: string }) {
    const data = [
        { name: 'L1', value: 25 }
    ];

    const circleSize = 120;

    return <ResponsiveContainer width="100%" height={120}>
        <RadialBarChart
            width={circleSize}
            height={circleSize}
            cy={circleSize / 2}
            innerRadius={50}
            outerRadius={80}
            barSize={20}
            data={[{ name: "default", value: props.value }]}
            startAngle={90}
            endAngle={-270}
        >
            <PolarAngleAxis
                type="number"
                domain={[0, 100]}
                angleAxisId={0}
                tick={false}
            />
            <RadialBar
                background
                dataKey="value"
                cornerRadius={circleSize / 2}
                fill={props.color}
            />
            <text
                x="50%"
                y={circleSize / 2}
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={18}
            >
                <Percentage value={props.value} />
            </text>
        </RadialBarChart>
    </ResponsiveContainer>
}