import React, { ReactNode } from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { cardStyles } from "./styles";

export default function InfoCard({ title, children }: { title: string, children: ReactNode }) {
    const classes = cardStyles();

    return <Card>
        <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom align="left">
                {title}
            </Typography>
            {children}
        </CardContent>
    </Card>;
}