import { API_ENDPOINT } from "./auth";

export type Response = {
    ok: boolean,
    error?: string
}

export function SetData(inputData: any, callback: CallableFunction, error: CallableFunction): Promise<Response> {
    const url = API_ENDPOINT + "/auth/write";
    const response = fetch(url, {
        method: "PUT",
        credentials: 'include',
        body: JSON.stringify(inputData),
    }).then(response => response.json()).then(value => callback(value)).catch(err => error(err));
    return response;
}