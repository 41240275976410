import React from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts';
import { kFormatter } from '../../api/common';
import { ValueSeries, Profile, Value } from '../../types';
import { Amount } from './fragments';

export default function FamilyLineChart({ data, estimateData, balanceData, profile, privacy }: { data: ValueSeries, estimateData?: boolean, balanceData?: boolean, profile: Profile, privacy: boolean }) {
    const CustomTooltip = (props: TooltipProps<number, string>) => {
        if (props.active && props.payload && props.payload.length) {
            return (
                <div style={{ backgroundColor: 'white', padding: 8, borderColor: "#eee", borderWidth: 1, borderStyle: "solid" }}>
                    {props.label}<br />
                    {props.payload.map((item: any) => {
                        if (item.value !== undefined) {
                            return <>{item.dataKey}: <Amount value={item.payload[item.dataKey]} privacy={privacy} /><br /></>
                        }
                        return <></>;
                    })}
                </div>
            );
        }
        return null;
    };

    return <ResponsiveContainer width="100%" height={200}>
        <LineChart width={300} height={100} data={data}>
            <XAxis dataKey="name" />
            {!privacy && <YAxis width={40} tickFormatter={(value, _index) => `${kFormatter(value)}`} />}
            <Line type="natural" dataKey="value" stroke={profile.color} strokeWidth={4} />
            {estimateData && <Line type="natural" dataKey="estimatedValue" stroke="gray" strokeWidth={4} strokeDasharray="5 5" />}
            {balanceData && <Line type="natural" dataKey="balance" stroke="gray" strokeWidth={4} />}
            <Tooltip content={(params: TooltipProps<number, string>) => <CustomTooltip {...params} />} />
        </LineChart>
    </ResponsiveContainer>
}