import React from "react";
import { DataGrid, GridCellParams, GridColDef } from "@material-ui/data-grid";
import { GetMonthlyStats } from "../../api/stats";
import { Value, ValueSeries, Profile } from "../../types";
import { Amount, Percentage } from "../common/fragments";
import InfoCard from "../common/InfoCard";
import { Chip } from "@material-ui/core";
import { getClassColor } from "../../api/common";

function getY2DMonths(year: number): Set<Date> {
    const endDate = new Date();
    const months = new Set<Date>();
    var currMonth = 0;
    var currDate = new Date(year, currMonth, 1);
    while (currDate.getFullYear() === year && currDate < endDate) {
        months.add(currDate);
        currMonth++;
        currDate = new Date(year, currMonth, 1);
    }
    return months;
}

function Destinations({ destinations }: { destinations: any }) {
    if (!destinations || !destinations.map) {
        return <></>;
    }
    destinations.sort((a: Value, b: Value) => {
        return ((a.class || '').localeCompare(b.class || ''));
    });
    return <>{destinations.map((item: Value) => <Chip size="small" label={item.destination} style={{ color: 'white', backgroundColor: getClassColor(item.class || '') }} />)}</>
}

export default function Y2DProgress({ savings, balance, income, profile, privacy }: { savings: ValueSeries, balance: ValueSeries, income: ValueSeries, profile: Profile, privacy: boolean }) {
    const stats = GetMonthlyStats(getY2DMonths((new Date()).getFullYear()), savings, balance, income, profile)
    const renderAmount = (params: GridCellParams) => <Amount value={parseInt(params.value?.toString() || '')} privacy={privacy} />
    const renderPercentage = (params: GridCellParams) => <Percentage value={parseInt(params.value?.toString() || '')} />
    const renderDestinations = (params: GridCellParams) => <Destinations destinations={params.value} />
    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Month', width: 180 },
        { field: 'income', headerName: 'Income', align: 'right', width: 130, renderCell: renderAmount },
        { field: 'savings', headerName: 'Savings', align: 'right', width: 130, renderCell: renderAmount },
        { field: 'spending', headerName: 'Expenses', align: 'right', width: 130, renderCell: renderAmount },
        { field: 'savingsRate', headerName: 'SR', align: 'right', width: 80, renderCell: renderPercentage },
        { field: 'savingDestinations', headerName: 'Destinations', align: 'right', flex: 1, renderCell: renderDestinations },
    ];
    return <InfoCard title="Year to Date statistics">
        <DataGrid rows={stats} columns={columns} autoPageSize hideFooterPagination autoHeight />
    </InfoCard>;
}
