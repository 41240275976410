import React from "react";
import { Box, Button, Card, CardContent, createStyles, Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import { GetBalance, getPensionProjection } from "../../api/balance";
import { getCurrentProfile, getProfiles } from "../../api/profiles";
import { Profile, InteractiveViewParams } from "../../types";
import PieChartCard from "../common/PieChartCard";
import { cardStyles } from "../common/styles";
import BorderLinearProgress from "../common/BorderLinearProgres";
import { useAsync } from 'react-use';
import AsyncView from "../common/AsyncView";
import { Amount } from "../common/fragments";
import { DataEntryDialog } from "./DataEntryDialog";
import AccountBreakdownPerYear from "./AccountBreakdown";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
    }),
);

function Age({ years, profile }: { years: number, profile: Profile }) {
    if (profile.name == "global") {
        return <>
            {new Date().getFullYear() - profile.birthYear + years - 1}/
            {new Date().getFullYear() - profile.birthYear + years + 1}
        </>
    }
    return <>{new Date().getFullYear() - profile.birthYear + years}</>
}

function ProgressWidget({ title, profile, balance, privacy }: { title: string, profile: string, balance?: number, privacy: boolean }) {
    const classes = cardStyles();
    const currentProfile = getCurrentProfile(profile)
    const realBalance = balance || 0;
    const timeToPension = getPensionProjection(currentProfile, realBalance);

    return <Card>
        <CardContent>
            {title && <Typography className={classes.title} color="textSecondary" gutterBottom align="left">
                {title}
            </Typography>}

            <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                    <BorderLinearProgress variant="determinate" value={timeToPension.value} barColor={currentProfile.color} />
                </Box>
                <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">{timeToPension.value}%</Typography>
                </Box>
            </Box>

            <p>Time to retirement: {timeToPension.years} years (age: <Age years={timeToPension.years} profile={currentProfile} />)</p>

            <p color="textSecondary">
                <small>Calculated with: <br />
                    ROI {timeToPension.roiPercentage * 100}%, yearly savings: <Amount value={timeToPension.yearlySavings} privacy={privacy} />, final balance: <Amount value={timeToPension.finalBalance} privacy={privacy} />.<br />
                    Current amount considered: <Amount value={realBalance} privacy={privacy} />.
                </small>
            </p>
        </CardContent>
    </Card>;
}

function BalanceCard({ profile, value, privacy }: { profile: string, value: any, privacy: boolean }) {
    const classes = cardStyles();

    return <Card>
        <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom align="left">
                Balance
            </Typography>
            <Typography variant="h5" component="h2" align="right">
                <Amount value={value.balance} privacy={privacy} />
            </Typography>

            {profile != "Shared" && <Box display="flex" alignItems="center">
                <Box minWidth={120} alignItems="left">
                    <Typography className={classes.subtitle} color="textSecondary" gutterBottom align="right">
                        Investments:
                    </Typography>
                </Box>
                <Box width="100%">
                    <Typography variant="h6" component="h2" align="right">
                        <Amount value={value.balanceNoPension - value.property - value.balanceCash} privacy={privacy} />
                    </Typography>
                </Box>
            </Box>}
            {profile != "Shared" && <Box display="flex" alignItems="center">
                <Box minWidth={120} alignItems="left">
                    <Typography className={classes.subtitle} color="textSecondary" gutterBottom align="right">
                        Pillar 2:
                    </Typography>
                </Box>
                <Box width="100%">
                    <Typography variant="h6" component="h2" align="right">
                        <Amount value={value.balanceP2} privacy={privacy} />
                    </Typography>
                </Box>
            </Box>}
            {profile != "Shared" && <Box display="flex" alignItems="center">
                <Box minWidth={120} alignItems="left">
                    <Typography className={classes.subtitle} color="textSecondary" gutterBottom align="right">
                        Pillar 3:
                    </Typography>
                </Box>
                <Box width="100%">
                    <Typography variant="h6" component="h2" align="right">
                        <Amount value={value.balanceP3} privacy={privacy} />
                    </Typography>
                </Box>
            </Box>}
            {(profile == "Shared" || profile == "global") && <Box display="flex" alignItems="center">
                <Box minWidth={120} alignItems="left">
                    <Typography className={classes.subtitle} color="textSecondary" gutterBottom align="right">
                        Property:
                    </Typography>
                </Box>
                <Box width="100%">
                    <Typography variant="h6" component="h2" align="right">
                        <Amount value={value.property} privacy={privacy} />
                    </Typography>
                </Box>
            </Box>}
            {(profile == "Shared" || profile == "global") && <Box display="flex" alignItems="center">
                <Box minWidth={120} alignItems="left">
                    <Typography className={classes.subtitle} color="textSecondary" gutterBottom align="right">
                        Cash:
                    </Typography>
                </Box>
                <Box width="100%">
                    <Typography variant="h6" component="h2" align="right">
                        <Amount value={value.balanceCash} privacy={privacy} />
                    </Typography>
                </Box>
            </Box>}
        </CardContent>
    </Card>
}

export default function HomePage(params: InteractiveViewParams) {
    const currentProfile = getCurrentProfile(params.profile)
    const classes = useStyles();
    const { value, loading, error } = useAsync(() => GetBalance(params), []);
    const year = new Date().getFullYear();
    const data = [year, year - 1];
    const breakdownProfiles = currentProfile.name == "global" ? getProfiles().filter(p => p.name != "global") : [currentProfile];


    return (
        <>
            <AsyncView value={value} loading={loading} error={error}>
                {value && <div className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={4}>
                            <BalanceCard profile={currentProfile.name} value={value} privacy={params.privacy} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <PieChartCard data={value.distribution} title="Diversification" privacy={params.privacy} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            {params.profile != "Shared" && <ProgressWidget title="Road to Independence" profile={params.profile} balance={value.balanceNoPP} privacy={params.privacy} />}
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Button onClick={() => { window.location.reload() }}>Reload</Button>
                            <p>
                                <small>Date: {params.date} Profile: {params.profile}</small>
                            </p>
                        </Grid>
                    </Grid>
                    {data.map((aYear) => {
                        return <>
                            <h3>{aYear}</h3>
                            <Grid container spacing={3}>

                                {breakdownProfiles.map((aProfile) =>
                                    <Grid item xs={12} lg={6}>
                                        <AccountBreakdownPerYear year={aYear} profile={aProfile} privacy={params.privacy} currentYear={aYear === year} />
                                    </Grid>
                                )}
                            </Grid>
                        </>;
                    })}
                </div>}
            </AsyncView>
            <p>
                {!params.privacy && <Link target='blank' href='https://docs.google.com/spreadsheets/d/1L6QkoDtenaCdDtHWg9zXbj9Rkw1JV3UDYFxfXkEpvGc/edit#gid=1492905682'>
                    <Button variant='outlined'>Spreadsheet</Button>
                </Link>}
            </p>
            <DataEntryDialog profile={currentProfile} setDate={params.setDate} />
        </>
    );
}
