import { Profile, Stats, ValueSeries, Account, Value } from "../types";
import { getLabel, getValueSeriesSum, MONTH } from "./common";
import { getAccountNames, getAggregatedAccountBalance } from "./performance";

export function getYearlyRecords(records: ValueSeries, year: number): ValueSeries {
    const yearStart = new Date(year, 0, 1).getTime() / 1000;
    const yearEnd = new Date(year, 11, 31, 23, 59).getTime() / 1000;
    return records.filter(record => (record.x >= yearStart && record.x <= yearEnd));
}

export function getMonthlyRecords(records: ValueSeries, year: number, month: number): ValueSeries {
    const monthStart = new Date(year, month, 1).getTime() / 1000;
    const monthEnd = month == 11 ? new Date(year + 1, 0, 1).getTime() / 1000 : new Date(year, month + 1, 1).getTime() / 1000;
    return records.filter(record => (record.x >= monthStart && record.x < monthEnd));
}

export function getAccountBalanceSum(records: ValueSeries, profile: string): number {
    const accounts = getAccountNames(records)
    var balance = 0

    Array.from(accounts).map(account => {
        balance += getAggregatedAccountBalance(account, records, profile)
    })
    return balance
}

export function getAccountPensionBalanceSum(records: ValueSeries, profile: string): number {
    records = records.filter(item => (item.class == "Pension" || item.class == "Pension 3a"))
    const accounts = getAccountNames(records)
    var balance = 0

    Array.from(accounts).map(account => {
        balance += getAggregatedAccountBalance(account, records, profile)
    })
    return balance
}

export function getAccountPropertyBalanceSum(records: ValueSeries, profile: string): number {
    records = records.filter(item => (item.class == "Property"))
    const accounts = getAccountNames(records)
    var balance = 0

    Array.from(accounts).map(account => {
        balance += getAggregatedAccountBalance(account, records, profile)
    })
    return balance
}

export function getRecordsNoP2(savings: ValueSeries): ValueSeries {
    return savings.filter(item => item.destination != "Pillar 2")
}

function getCashRecords(savings: ValueSeries): ValueSeries {
    return savings.filter(item => item.class === "Cash")
}

export function getPropertyRecords(savings: ValueSeries): ValueSeries {
    return savings.filter(item => item.class === "Property")
}

export function GetYearlyStats(years: Set<number>, savings: ValueSeries, balance: ValueSeries, income: ValueSeries, profile: Profile): Array<Stats> {
    var previousYear = {
        id: "",
        year: 0,
        month: 0,
        savings: 0,
        income: 0,
        balance: 0,
        balanceP2: 0,
        balanceP3: 0,
        balanceNoP2: 0,
        spending: 0,
        balanceDelta: 0,
        savingsRate: 0,
        balanceIncreaseRate: 0,
        balanceIncrease: 0,
        yoyIncrease: 0,
        balancePension: 0,
        balanceNoPension: 0,
        cash: 0,
        property: 0,
        prevYear: {},
    }
    const yearsList = Array.from(years)
    yearsList.sort()
    const stats = yearsList.map(year => {
        var savingsWithoutP2 = getRecordsNoP2(savings)
        var yearSavings = getValueSeriesSum(getYearlyRecords(savingsWithoutP2, year))
        var yearIncome = getValueSeriesSum(getYearlyRecords(income, year))
        const yearBalanceRecords = getYearlyRecords(balance, year)
        var balanceWithoutP2 = getRecordsNoP2(yearBalanceRecords)
        var balanceCash = getCashRecords(yearBalanceRecords)
        var yearBalance = getAccountBalanceSum(yearBalanceRecords, profile.name)
        var yearBalanceNoP2 = getAccountBalanceSum(balanceWithoutP2, profile.name)
        var cash = getAccountBalanceSum(balanceCash, profile.name)
        var balanceProperty = getPropertyRecords(yearBalanceRecords)
        var property = getAccountBalanceSum(balanceProperty, profile.name)
        var yearPensionBalance = getAccountPensionBalanceSum(yearBalanceRecords, profile.name)
        var yearDeltaNoP2 = yearBalanceNoP2 - previousYear.balanceNoP2 - yearSavings


        previousYear = {
            id: year.toString(),
            year: year,
            month: 0,
            savings: yearSavings,
            income: yearIncome,
            balance: yearBalance,
            balanceP2: yearBalance - yearBalanceNoP2,
            balanceP3: yearPensionBalance - (yearBalance - yearBalanceNoP2),
            balanceNoP2: yearBalanceNoP2,
            spending: yearIncome - yearSavings,
            balanceDelta: yearDeltaNoP2,
            savingsRate: Math.round(yearSavings / yearIncome * 100) || 0,
            balanceIncreaseRate: (previousYear.balanceNoP2 > 0 ? Math.round((yearDeltaNoP2) / previousYear.balanceNoP2 * 100) : 0),
            balanceIncrease: yearBalance - previousYear.balance,
            yoyIncrease: previousYear.balance ? (yearBalance - previousYear.balance) * 100 / previousYear.balance : 0,
            balancePension: yearPensionBalance,
            balanceNoPension: yearBalance - yearPensionBalance,
            cash: cash,
            property: property,
            prevYear: previousYear,
        }
        return previousYear
    })

    stats.sort((a, b) => -(a.year - b.year))
    return stats
}

export function GetMonthlyStats(months: Set<Date>, savings: ValueSeries, balance: ValueSeries, income: ValueSeries, profile: Profile): Array<Stats> {
    const monthList = Array.from(months)
    const stats = monthList.map(date => {
        var savingsWithoutP2 = getRecordsNoP2(savings)
        var monthSavingsSeries = getMonthlyRecords(savingsWithoutP2, date.getFullYear(), date.getMonth())
        var monthSavings = getValueSeriesSum(monthSavingsSeries)
        var monthIncome = getValueSeriesSum(getMonthlyRecords(income, date.getFullYear(), date.getMonth()))
        var destinations = new Map<string, Value>();
        monthSavingsSeries.map(item => destinations.set(item.destination || "", item));

        var month = {
            id: getLabel(date.getTime() / 1000, MONTH),
            year: date.getFullYear(),
            month: date.getMonth(),
            income: monthIncome,
            savings: monthSavings,
            spending: monthIncome > 0 ? monthIncome - monthSavings : 0,
            balanceDelta: 0,
            savingsRate: monthIncome > 0 ? Math.round(monthSavings / monthIncome * 100) : 0,
            balanceIncrease: 0,
            balanceIncreaseRate: 0,
            balancePension: 0,
            balanceNoPension: 0,
            balanceNoP2: 0,
            balance: 0,
            savingDestinations: Array.from(destinations.values()),
        }
        return month
    })

    stats.sort((a, b) => -(a.year - b.year))
    return stats
}

export function getAverageStats(stats: Array<Stats>): Stats {
    const sumStats = { id: "AVG", year: 0, month: 0, income: 0, savings: 0, spending: 0, balance: 0, balanceNoP2: 0, balanceNoPension: 0, balanceIncrease: 0, balanceDelta: 0, balanceIncreaseRate: 0, balancePension: 0, savingsRate: 0, property: 0 };

    stats.map(year => {
        sumStats.savingsRate += year.savingsRate;
        sumStats.balanceIncreaseRate += year.balanceIncreaseRate;
        sumStats.income += year.income;
        sumStats.savings += year.savings;
        sumStats.spending += year.spending;
    })

    const averageStats = {
        id: "AVG", year: 0, month: 0,
        income: sumStats.income / stats.length,
        savings: sumStats.savings / stats.length,
        spending: sumStats.spending / stats.length,
        balanceIncreaseRate: sumStats.balanceIncreaseRate / stats.length,
        savingsRate: sumStats.savingsRate / stats.length,
        balance: "-",
        balanceNoP2: 0, balanceNoPension: 0, balanceIncrease: 0,
        property: 0,
        balanceDelta: "-",
        balancePension: 0,
        yoyIncrease: "-",
        prevYear: {}
    };

    return averageStats;
}
