import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, LabelList, Tooltip, TooltipProps } from 'recharts';
import { getAccountSeriesSum, getClassColor, getDestinationColor, getValueSeriesSum } from '../../api/common';
import { AccountSeries } from '../../types';
import { Amount } from './fragments';

export default function FamilyPieChart({ dataPrimary, dataSecondary, privacy }: { dataPrimary: AccountSeries, dataSecondary?: AccountSeries, privacy: boolean }) {
    const total = getAccountSeriesSum(dataPrimary)
    const CustomTooltip = (props: TooltipProps<number, string>) => {
        if (props.active && props.payload && props.payload.length) {
            const percentage = Math.round((props.payload[0].value || 0) / total * 10000) / 100;
            return (
                <div style={{ backgroundColor: 'white', padding: 8, borderColor: "#eee", borderWidth: 1, borderStyle: "solid" }}>
                    {props.payload[0].name}: {percentage}%<br/><Amount value={props.payload[0].value || 0} privacy={privacy} />
                </div>
            );
        }
        return null;
    };
    return (
        <ResponsiveContainer width="100%" height={200}>
            <PieChart width={400} height={400}>
                <Pie data={dataPrimary} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={0} outerRadius={70} fill="#82ca9d" startAngle={180} endAngle={-180}>
                    {!dataSecondary && <LabelList dataKey="name" position="inside" stroke="textSecondary" />}
                    {
                        dataPrimary.map((_entry: any, index: number) => (
                            <Cell key={`cell-${index}`} fill={getDestinationColor(_entry.name, _entry.class, dataPrimary)} />
                        ))
                    }
                </Pie>
                {dataSecondary && <Pie data={dataSecondary} dataKey="value" nameKey="class" cx="50%" cy="50%" innerRadius={80} outerRadius={90} fill="#82ca9d" startAngle={180} endAngle={-180}>
                    <LabelList dataKey="name" position="outside" stroke="textSecondary" />
                    {
                        dataSecondary.map((_entry: any, index: number) => (
                            <Cell key={`cell-${index}`} fill={getClassColor(_entry.name)} />
                        ))
                    }
                </Pie>}
                <Tooltip content={(props: TooltipProps<number, string>) => <CustomTooltip {...props} />} />
            </PieChart>
        </ResponsiveContainer>
    );
}
