import React from "react";
import { Button, ButtonGroup, createStyles, Fab, FormControl, FormControlLabel, LinearProgress, makeStyles, Radio, RadioGroup, Theme } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getTerms } from "../../api/common";
import { Profile } from "../../types";
import Alert from "@material-ui/lab/Alert";
import { SetData, Response } from "../../api/data";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            position: 'fixed',
            bottom: 50 + theme.spacing(2),
            right: theme.spacing(2),
        },
    }),
);

function SelectEntry({ terms, callback, value }: { terms: Array<string>, callback: CallableFunction, value: string }) {
    const [selected, setSelected] = React.useState<String>(value);

    return <div>
        <ButtonGroup size="small" aria-label="small outlined button group">
            {terms.map(term => <Button onClick={() => { setSelected(term); callback(term) }} variant={selected == term ? "contained" : "outlined"} color="primary">{term}</Button>)}
        </ButtonGroup>
    </div >
}

export function DataEntryDialog({ profile, setDate }: { profile: Profile, setDate: CallableFunction }) {
    const classes = useStyles();
    const [error, setError] = React.useState<string>("");
    const [open, setOpen] = React.useState(false);
    const [inputProfile, setProfile] = React.useState<string>(profile.name == "global" ? "" : profile.name);
    const [dataType, setDataType] = React.useState<string>("balance");
    const [source, setSource] = React.useState<string>("");
    const [destination, setDestination] = React.useState<string>("");
    const [amount, setAmount] = React.useState<string>("");
    const [loading, setLoading] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleDataTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDataType((event.target as HTMLInputElement).value);
    };
    const handleProfileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProfile((event.target as HTMLInputElement).value);
    };
    const handleSubmit = () => {
        if (!inputProfile) {
            setError("Please select a profile");
            return;
        }
        if (!amount) {
            setError("Please input an amount");
            return;
        }
        if (dataType === "income" && !source) {
            setError("Please select a source");
            return;
        }
        if (dataType !== "income" && !destination) {
            setError("Please select a destination");
            return;
        }
        setError("");
        setLoading(true);
        const payload = {
            type: dataType,
            profile: inputProfile,
            amount: amount,
            destination: destination,
            source: source,
        }
        const result = SetData(payload, (result: Response) => {
            if (result.ok) {
                setLoading(false);
                // do the async dance
                handleClose();
                setDate(Date());
            } else {
                setLoading(false);
                setError("failed to put")
            }
        }, (err: string) => {
            setLoading(false)
            setError("failed to submit" + err);
        });
    }

    const terms = getTerms();

    return (
        <div>
            <Fab color="secondary" className={classes.fab} onClick={handleClickOpen}>
                <AddIcon />
            </Fab>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                {loading && <LinearProgress />}
                <DialogTitle id="form-dialog-title">Input Data</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Use this form to input balance, savings or income.
                        {error && <Alert severity="error">{error}</Alert>}
                    </DialogContentText>

                    <FormControl component="fieldset">
                        <RadioGroup aria-label="input" name="input1" value={inputProfile} onChange={handleProfileChange} row>
                            {terms.profiles.map(p => <FormControlLabel value={p} control={<Radio />} label={p} />)}
                        </RadioGroup>
                    </FormControl>
                    <br />

                    <FormControl component="fieldset">
                        <RadioGroup aria-label="input" name="input2" value={dataType} onChange={handleDataTypeChange} row>
                            <FormControlLabel value="balance" control={<Radio />} label="Balance" />
                            <FormControlLabel value="savings" control={<Radio />} label="Saving" />
                            <FormControlLabel value="income" control={<Radio />} label="Income" />
                        </RadioGroup>
                    </FormControl>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="amount"
                        label="Amount"
                        type="number"
                        value={amount}
                        onChange={(event) => setAmount(event.target.value)}
                        fullWidth />

                    {dataType === "income" && <SelectEntry terms={terms.sources} callback={setSource} value={source} />}
                    {dataType !== "income" && <SelectEntry terms={terms.destinations} callback={setDestination} value={destination} />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
