import { GrouppedValueSeries, Value, ValueSeries, ViewParams } from "../types";
import { API_ENDPOINT } from "./auth";
import { getDataRollup, getProfileFilter, getYearAgo, getYearStart, MONTH, YEAR } from "./common";

export async function GetIncomeHistory(params: ViewParams): Promise<ValueSeries> {
    const url = API_ENDPOINT + "/auth/income" + (params.profile !== "global" ? "?profile=" + params.profile : "");
    const response = await fetch(url, {
        credentials: 'include',
    }).then(response => response.json());
    const data = await response;
    return data && data.income && data.income.filter(getProfileFilter(params)).map((item: { date: string, amount: number }) => {
        return {
            x: Date.parse(item?.date) / 1000,
            name: item?.date,
            value: item?.amount,
            ...item
        };
    }) || [];
}

export function GetIncomeHistoryGroupped(rawData: ValueSeries): GrouppedValueSeries {
    if (!rawData || !rawData.sort) {
        rawData = Array<Value>();
    }
    var result = new Map();
    const now = Date.now() / 1000
    const yearStart = getYearStart();
    const yearAgo = getYearAgo();
    rawData.sort((a, b) => (a.x - b.x));
    result.set("y2d", getDataRollup(rawData, MONTH, "sum", yearStart, now));
    result.set("12m", getDataRollup(rawData, MONTH, "sum", yearAgo, now));
    result.set("24m", getDataRollup(rawData, MONTH * 2, "sum", yearAgo - YEAR, now));
    result.set("all", getDataRollup(rawData, YEAR, "sum", 0, now));
    return {
        groups: result,
        raw: rawData,
    };
}