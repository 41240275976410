import React from "react"
import { Card, CardContent, Typography } from "@material-ui/core"
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FamilyLineChart from "./FamilyLineChart";
import { GrouppedValueSeries, Profile, ValueSeries } from "../../types";
import { cardStyles } from "./styles";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    tab: {
        minWidth: 0,
    },
}));

function TimeTabs({ data, profile, privacy, balanceData }: { data: Map<String, ValueSeries>, profile: Profile, privacy: boolean, balanceData?: boolean }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const keys = Array.from(data.keys())
    return (
        <>
            {keys.map((key, index) => (
                <TabPanel value={value} index={index}>
                    <FamilyLineChart data={data.get(key) || []} profile={profile} privacy={privacy} balanceData={balanceData} />
                </TabPanel>
            ))
            }

            <Tabs value={value} onChange={handleChange} aria-label="simple tabs" variant="scrollable" scrollButtons="auto">
                {keys.map((key, index) =>
                    <Tab label={key} {...a11yProps(index)} className={classes.tab} />
                )}
            </Tabs>
        </>
    );
}


export default function LineChartCard({ data, title, profile, privacy, balanceValues }: { data: GrouppedValueSeries, title?: string, profile: Profile, privacy?: boolean, balanceValues?: boolean }) {
    const classes = cardStyles();
    privacy = privacy || false;

    return <Card>
        <CardContent>
            {title && <Typography className={classes.title} color="textSecondary" gutterBottom align="left">
                {title}
            </Typography>
            }
            {(data.groups.size > 1) && <TimeTabs data={data.groups} profile={profile} privacy={privacy} balanceData={balanceValues || false} />}
            {(data.groups.size === 1) && <FamilyLineChart data={data.groups.values().next().value} profile={profile} privacy={privacy} balanceData={balanceValues || false} />}
        </CardContent>
    </Card>;
}