import { Value, ValueSeries, ViewParams, Account, AccountSeries } from "../types";
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';


export const MONTH = 3600 * 24 * 30;
export const YEAR = 3600 * 24 * 365;

export function getLabel(timestamp: number, rollup: number) {
    const date = new Date(timestamp * 1000);
    if (rollup === MONTH) {
        return date.toLocaleDateString('en-US', { "month": "long" })
    } else if (rollup === MONTH * 2) {
        return date.toLocaleDateString('en-US', { "month": "long" })
    } else if (rollup === YEAR) {
        return date.getFullYear().toString();
    }
    return date.toLocaleDateString();
}

export function getDataRollup(data: Array<Value>, rollup: number, method: string, limit: number, until: number): ValueSeries {
    var bucket: Map<Number, Array<Value>> = new Map();

    data.map(item => {
        const rollupKey = item.x - (item.x % rollup);
        if (item.x <= until && item.x >= limit) {
            var values = bucket.get(rollupKey) || [];
            values.push(item);
            bucket.set(rollupKey, values)
        }
    });
    return Array.from(bucket.keys()).map((key) => {
        const values = bucket.get(key) || [];
        if (method == "sum") {
            if (values.length === 1) {
                return {
                    ...values[0],
                    name: getLabel(values[0].x, rollup),
                }
            } else {
                var value = 0;
                values.map(a => (value += a.value || 0))
                return {
                    ...values[0],
                    value: value,
                    name: getLabel(values[values.length - 1].x, rollup)
                }
            }
        }
        return {
            x: 0,
            name: "test",
            value: 0,
        }
    })
}

export function getYearStart(): number {
    return new Date(new Date().getFullYear(), 0, 1).getTime() / 1000;
}

export function getYearAgo(): number {
    return (new Date()).getTime() / 1000 - YEAR;
}

export function getProfileFilter(params: ViewParams) {
    return (item: { profile: string }) => (params.profile === "global" || item.profile === params.profile);
}

export function getGrouppedAccounts(data: Array<Account>, method: string): AccountSeries {
    var bucket: Map<String, Array<Account>> = new Map();

    data.map(item => {
        const rollupKey = item.class || '';
        var values = bucket.get(rollupKey) || [];
        values.push(item);
        bucket.set(rollupKey, values)
    });
    return Array.from(bucket.keys()).map((key) => {
        const values = bucket.get(key) || [];
        if (method === "sum") {
            if (values.length === 1) {
                var a = values[0];
                return {
                    name: a.class  || a.name,
                    value: a.value,
                    class: a.class || ""
                }
            } else {
                var finalValue = values.reduce((a: Account, b: Account) => {
                    return {
                        name: a.class || a.name,
                        value: (a.value + b.value),
                        class: a.class || "",
                    }
                })
                return finalValue
            }
        }
        return {
            name: "test",
            value: 0,
        }
    })
}

export function getValueSeriesSum(series: ValueSeries): number {
    var sum = 0
    series.map(item => (sum += item.value || 0))
    return sum
}

export function getAccountSeriesSum(series: AccountSeries): number {
    var sum = 0
    series.map(item => (sum += item.value))
    return sum
}

export function getValueSeriesInterval(series: ValueSeries, start: number, end: number): ValueSeries {
    return series.filter(item => item.x >= start && item.x <= end)
}

export function getClassColor(className: string, defaultClass?: string): string {
    if (className === "Cash") {
        return blue[700];
    } else if (className === "Investment") {
        return red[700]
    } else if (className === "Pension" || className == "Pillar 2" || className == "Pension 3a") {
        return orange[700]
    } else if (className === "Property") {
        return "#555";
    }
    return (defaultClass && getClassColor(defaultClass)) || "gray";
}

export function getDestinationColor(destination: string, className: string, destinations: AccountSeries): string {
    function _getColor(color: any, index: number): string {
        if (index == 0) {
            return color[700];
        } else if (index === 1) {
            return color[600];
        } else if (index === 2) {
            return color[500];
        } else if (index === 3) {
            return color[400];
        } else if (index === 4) {
            return color[300];
        }
        return color[200];
    }
    const peers = destinations.filter(d => d.class === className).map(d => d.name)
    peers.sort()

    if (className === "Cash") {
        return _getColor(blue, peers.indexOf(destination) % 5)
    } else if (className === "Investment") {
        return _getColor(red, peers.indexOf(destination) % 5)
    } else if (className === "Pension" || className === "Pension 3a") {
        return _getColor(orange, peers.indexOf(destination) % 5)
    } else if (className === "Property") {
        return "#555";
    }
    return "gray";
}

export function getTerms() {
    return {
        "profiles": ["Alex", "Claudia", "Shared"],
        "sources": ["Salary", "Other", "Taxes"],
        "destinations": ["3a VIAC", "IBKR", "IBKR Cash", "GOOG", "GYG", "Savings Account"],
    }
}

function kmFormatter(num: number, divide: number, letter: string): string {
    return (Math.sign(num) * (Math.abs(num) / divide)).toString() + letter;
}
function mFormatter(num: number): string {
    return kmFormatter(num, 1000000, 'M');
}

export function kFormatter(num: number): string {
    return (
        Math.abs(num) > 999999 ?
            mFormatter(num) : (
                Math.abs(num) > 999 ? kmFormatter(num, 1000, 'k') : (Math.sign(num) * Math.abs(num)).toString()
            )
    );
}
