import React from 'react';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, ReferenceLine, Tooltip, Cell } from 'recharts';
import { getDestinationColor, kFormatter } from '../../api/common';
import { PerformanceSeries, Profile } from '../../types';

export default function FamilyBarChart({ data, profile }: { data: PerformanceSeries, profile: Profile }) {
    return <ResponsiveContainer width="100%" height={200}>
        <BarChart width={150} height={40} data={data}>
            <Tooltip />
            <Bar dataKey="value" fill={profile.color}>
                {
                    data.map((_entry: any, index: number) => (
                        <Cell key={`cell-${index}`} fill={getDestinationColor(_entry.name, _entry.class, data)} />
                    ))
                }
            </Bar>
            <XAxis dataKey="name" />
            <YAxis width={40} tickFormatter={(value, _index) => `${value}%`} />
            <ReferenceLine y={0} stroke="#000" />

        </BarChart>
    </ResponsiveContainer >;
}
