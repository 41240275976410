import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const cardStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: 14,
        },       
        subtitle: {
            fontSize: 12,
        },
    }),
);