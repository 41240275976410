import React from "react"
import { Card, CardContent, makeStyles, Tab, Tabs, Theme, Typography } from "@material-ui/core"
import { GrouppedPerformanceSeries, PerformanceSeries, Profile } from "../../types";
import { cardStyles } from "./styles";
import FamilyBarChart from "./FamilyBarChart";


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    tab: {
        minWidth: 0,
    },
}));

function TimeTabs({ data, profile, privacy }: { data: Map<String, PerformanceSeries>, profile: Profile, privacy: boolean }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };


    return (
        <>
            {Array.from(data.keys()).map((key, index) => (
                <TabPanel value={value} index={index}>
                    <FamilyBarChart data={data.get(key) || []} profile={profile} />
                </TabPanel>
            ))
            }

            <Tabs value={value} onChange={handleChange} aria-label="simple tabs" variant="scrollable" scrollButtons="auto">
                {Array.from(data.keys()).map((key, index) =>
                    <Tab label={key} {...a11yProps(index)} className={classes.tab} />
                )}
            </Tabs>
        </>
    );
}

export default function BarChartCard({ data, title, profile }: { data: GrouppedPerformanceSeries, title?: string, profile: Profile }) {
    const classes = cardStyles();

    return <Card>
        <CardContent>
            {title && <Typography className={classes.title} color="textSecondary" gutterBottom align="left">
                {title}
            </Typography>}
            {(data.size > 1) && <TimeTabs data={data} profile={profile} privacy={false} />}
            {(data.size === 1) && <FamilyBarChart data={data.values().next().value} profile={profile} />}
        </CardContent>
    </Card>;
}